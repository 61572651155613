<template>
  <div>
    <b-card title="Que disent-ils ?">
      <div class="d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1 text-uppercase">Etat</label>
            <b-form-select
              v-model="currentState"
              :options="stateOptions"
            />
          </div>
        </b-form-group>
      </div>
      <b-table
        hover
        responsive
        show-empty
        empty-text="Aucune aide en attente"
        :items="items"
        :fields="fields"
        :busy="loaded"
      >
        <template #table-busy>
          <div class="d-flex justify-content-center mb-3">
            <b-spinner
              variant="primary"
              label="Loading..."
            />
          </div>
        </template>
        <template v-slot:cell(date)="data">
          {{ data.item.created_at | moment("DD/MM/YY HH:mm") }}
        </template>
        <template v-slot:cell(note)="data">
          <b-form-rating
            id="rating-readonly"
            class="p-0"
            no-border
            :value="data.item.note"
            readonly
            :variant="data.item.note >= 3 ? 'success' : 'danger'"
          />
        </template>
        <template v-slot:cell(action)="data">
          <span class="whitespace-no-wrap">
            <feather-icon
              icon="EditIcon"
              svg-classes="w-5 h-5 hover:text-success stroke-current"
              class="text-warning"
              @click="openModal(data.item)"
            />
          </span>
          <span class="whitespace-no-wrap">
            <feather-icon
              icon="TrashIcon"
              svg-classes="w-5 h-5 hover:text-danger stroke-current"
              class="text-danger ml-1"
              @click="openDeleteModal(data.item)"
            />
          </span>
        </template>
      </b-table>
      <div class="mt-2">
        <div class="float-left">
          <b-form-select
            v-model="perPage"
            :options="pageOptions"
          />
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          last-number
          align="right"
        />
      </div>
    </b-card>
    <b-modal
      v-if="showModal"
      v-model="showModal"
      centered
      hide-footer
      title="Modification"
      :no-close-on-backdrop="process"
      :hide-header-close="process"
      :busy="process"
    >
      <div>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Note">
              <b-form-rating
                id="rating-readonly"
                class="p-0"
                no-border
                :value="currentReview.note"
                readonly
                :variant="currentReview.note >= 3 ? 'success' : 'danger'"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Utilisateur">
              <b-form-input
                v-model="currentReview.name"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Avis">
              <b-form-textarea
                v-model="currentReview.review"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Etat d'affichage">
              <b-form-select
                v-model="$v.state.$model"
                :options="showOption"
                :class="{ 'is-invalid': $v.state.$error }"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div
              v-if="process"
              class="d-flex justify-content-center mt-1"
            >
              <b-spinner
                variant="primary"
                label="Loading..."
              />
            </div>
            <div class="mt-3">
              <b-button
                type="submit"
                variant="outline-primary"
                class="ml-2 float-right"
                :disabled="process"
                @click="tryToUpdate"
              >
                Modifier
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      v-if="showDeleteModal"
      v-model="showDeleteModal"
      centered
      hide-footer
      title="Suppression"
      :no-close-on-backdrop="delProcess"
      :hide-header-close="delProcess"
      :busy="delProcess"
    >
      <div>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Note">
              <b-form-rating
                id="rating-readonly"
                class="p-0"
                no-border
                :value="currentReview.note"
                readonly
                :variant="currentReview.note >= 3 ? 'success' : 'danger'"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Utilisateur">
              <b-form-input
                v-model="currentReview.name"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Avis">
              <b-form-textarea
                v-model="currentReview.review"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Etat d'affichage">
              <b-form-select
                v-model="state"
                :options="showOption"
                disabled
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div
              v-if="delProcess"
              class="d-flex justify-content-center mt-1"
            >
              <b-spinner
                variant="danger"
                label="Loading..."
              />
            </div>
            <div class="mt-3">
              <b-button
                type="submit"
                variant="outline-danger"
                class="ml-2 float-right"
                :disabled="delProcess"
                @click="tryToDelete"
              >
                Supprimer
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      items: [],
      currentPage: 1,
      currentState: 1,
      loaded: false,
      perPage: 10,
      totalItems: 0,
      showModal: false,
      showDeleteModal: false,
      showOption: [
        { value: true, text: 'Affiché' },
        { value: false, text: 'Non affiché' },
      ],
      stateOptions: [
        { value: 1, text: 'Affiché' },
        { value: 0, text: 'Non affiché' },
      ],
      pageOptions: [5, 10, 15],
      currentReview: null,
      state: null,
      fields: [
        { key: 'name', value: 'Utilisateur' },
        { key: 'review', value: 'Avis' },
        { key: 'note', value: 'note' },
        { key: 'date', value: 'Date' },
        { key: 'action', value: 'Action' },
      ],
    }
  },
  validations: {
    state: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      success: 'updateReviewSuccess',
      error: 'updateReviewError',
      process: 'updateReviewProcess',

      delSuccess: 'deleteReviewSuccess',
      delError: 'deleteReviewError',
      delProcess: 'deleteReviewProcess',
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getAllReviews()
      },
    },
    currentState() {
      this.getAllReviews()
    },
    delSuccess(val) {
      if (val) {
        this.closeDeleteModal()
        this.getAllReviews()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Suppression de l'avis",
            text: val.message,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    delError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Suppression de l'avis",
            text: 'Une erreur est survenue',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    success(val) {
      if (val) {
        this.closeModal()
        this.getAllReviews()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Modification de l'avis",
            text: 'Modifié avec succes',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Modification de l'avis",
            text: 'Une erreur est survenue',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['updateReview', 'deleteReview']),
    openModal(item) {
      this.currentReview = item
      this.state = item.state
      this.showModal = true
    },
    closeModal() {
      this.currentReview = null
      this.showModal = false
    },
    openDeleteModal(item) {
      this.currentReview = item
      this.state = item.state
      this.showDeleteModal = true
    },
    closeDeleteModal() {
      this.currentReview = null
      this.showDeleteModal = false
    },
    tryToUpdate() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }
      this.updateReview({
        id: this.currentReview.id,
        state: this.state,
      })
    },
    tryToDelete() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }
      this.deleteReview(this.currentReview.id)
    },
    async getAllReviews() {
      this.loaded = true
      this.items = await this.$http
        .get(`/reviews/all-state/${this.currentState}/${this.perPage}?page=${this.currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.currentToken}`,
            },
          })
        .then(res => {
          const { data } = res
          this.loaded = false
          this.totalItems = data.meta.total
          return data.data
        })
        .catch(() => {
          this.loaded = false
          return []
        })
    },
  },
}
</script>

<style>

</style>
